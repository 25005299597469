import React from 'react'
import Highlight from '../../../components/Highlight';
import { StaticImage } from "gatsby-plugin-image";
import { ThreeColumns, Container } from '../../../GlobalStyles';

import useI18n from '../../../i18n/useI18n';

const SecondSection = () => {

    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'Look at what',
        es: 'Entérate'
    }    
    const title = {
        en: 'happens in your business',
        es: 'de lo que pasa en tu empresa'
    }
    
    return (
        <Container>
            <ThreeColumns>
                <div className='col-1'data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-duration='700'>
                    <StaticImage
                        src="../../../images/appsinti-2.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="appsinti-2"
                        className="img-container"
                        imgClassName="img-fluid"
                    />
                </div>

                <div className='center'data-sal="slide-up" data-sal-easing="ease-in-circ">
                    <h2><Highlight text={translateObject(highlightTitle)}/> {translateObject(title)}</h2>
                    <p>
                        {translateObject({
                            en: `Use the connectivity with cameras and microphones of your business 
                            to see in real time what is happening inside. You also receive notifications 
                            when customers give a low rating to the service they received, 
                            so you can be aware that everything is in order.`,
                            es: `Utiliza la conectividad con cámaras y micrófonos de tu negocio 
                            para ver en tiempo real lo que ocurre en su interior. También recibiráss 
                            notificaciones cuando los clientes dejenn una calificación baja al servicio que recibieron, 
                            para que puedas estar al tanto de que todo está en orden.`
                        })} 
                    </p>                        
                </div>

                <div className='col-3'data-sal='slide-left' data-sal-easing="ease-in-circ" data-sal-duration='700'>
                    <StaticImage
                        src="../../../images/appsinti-3.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="appsinti-3"
                        className='img-container'
                        imgClassName='overflowed-img'
                    />
                </div>
            </ThreeColumns>
        </Container>
    )
};

export default SecondSection;
import React from "react";
import Seo from "../components/seo";
import ProductLayout from "../containers/ProductLayout";
import { Section } from '../GlobalStyles.jsx';
import { graphql, useStaticQuery } from "gatsby";
import '../globalStyles.css';
import { BG_DARK, BG } from '@etiquette-ui/colors';
import Description from "../sections/Products/Appsinti/description";
import SecondSection from "../sections/Products/Appsinti/second-section";
import { nominalTypeHack } from "prop-types";

const Appsinti = () => {
  const dataquery = useStaticQuery(query);
  const datainfo = dataquery.allFile.edges;

  const data = {
    currentPage: 1,
    title: 'Appsinti',
    heroLogo: datainfo[0].node.childrenImageSharp[0].fluid,
    heroAltLogo: datainfo[0].node.base,
    heroImage: datainfo[3].node.childrenImageSharp[0].fluid,
    heroAlt: datainfo[3].node.base,
    heroMobile: datainfo[3].node.childrenImageSharp[0].fluid,
    heroAltMobile: datainfo[3].node.base,
    banerImg: datainfo[2].node.childrenImageSharp[0].fluid,
    banerAlt: datainfo[2].node.base,
    banerImgMobile: datainfo[2].node.childrenImageSharp[0].fluid,
    banerImgAltMobile: datainfo[2].node.base,
    features: [
      {
        en: 'Listen to the audio from the restaurant’s microphones',
        es: 'Escucha el audio de los micrófons de cada restaurant'
      },
      {
        en: 'Check the weather for today and the next 5 days',
        es: 'Monitore el clima diario y de los siguientes cinco días'
      },
      {
        en: 'View and manage the cameras available in the restaurant',
        es: 'Monitorea las cámaras disponibles de cada restaurant'
      },
      {
        en: 'Charts by periods of time with the information of sales, cash inflow, cost and cancellations',
        es: 'Estádisticas gráficas por periódos con información de ventas, flujo de ventas, costos y cancelaciones'
      },
      {
        en: 'Overview of dashboard highlights',
        es: 'Descripción general de los destacados del Dashboard'
      },
      {
        en: 'Follow up by mail or by call to customers who wanted to be contacted through Dotell',
        es: 'Seguimiento por email o llamadas para clientes interesados en Dotell'
      },
      {
        en: 'Create and manage email templates to send them to clients according to the different scenarios you require',
        es: 'Crea y administra platillas de correo para enviar a clientes y gestiona la audiencia por cada plantilla'
      },
      {
        en: 'Receive notifications about the performance of qualified personnel through Dotell',
        es: 'Recibe notificaciones sobre el rendimiento del personal con base en la información recolectada por Dotell'
      },
      {
        en: 'Manage different types of notifications for better monitoring',
        es: 'Gestiona diferentes tipos de notificaciones para mejorar el monitoreo de tu empresa'
      },
    ],
  };

  return (
    <ProductLayout data={data} >
      <Seo title={data.title} />

      <Section color={BG}>
        <Description location={data.title} />
      </Section>
      <Section color={BG_DARK}>
        <SecondSection />
      </Section>
    </ProductLayout >
  );
};
export default Appsinti;

const query = graphql`
query appsinti {
    allFile(
      filter: {extension: {regex: "/(png)/"}, 
        name: {in: [
          "appsinti-hero",
          "appsinti-icon",
          "appsinti-banner",
          "digitalmenu-hero-mobile",
          "digitalmenu-banner-mobile"
        ]}}
    ) {
      edges {
        node {
          base
          childrenImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }  
`;
import React from 'react'
import Position from '../../../components/Position';
import Highlight from '../../../components/Highlight';
import { Container, Space, Center, Grid } from '../../../GlobalStyles';
import { StaticImage } from "gatsby-plugin-image";

import useI18n from '../../../i18n/useI18n';

const Description = ({ location }) => {

    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'Have all',
        es: 'Todo el control'
    }    
    const title = {
        en: 'the control in the palm of your hand',
        es: 'en la palma de tu mano'
    }
    
    return (
        <Container>
            <Space />
            <Position location={location} />
            <Center>
                <h2 data-sal="slide-up" data-sal-easing="ease-in-circ">
                    <Highlight text={translateObject(highlightTitle)}/> {translateObject(title)}
                </h2>
                <p data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-delay=".2s">
                    {translateObject({
                        en: `As a manager or owner you do not need to be physically in your business 
                        to follow up and be aware of the activities that are carried out in it. 
                        Appsinti helps you keep track of activities when you cannot be present.`,
                        es: `Como gerente o dueño no necesitas estar físicamente en tu negocio 
                        para dar seguimiento y estar al tanto de las actividades que se realizan en el mismo. 
                        Appsinti te ayuda a realizar un seguimiento de las actividades cuando no puedas estar presente.`
                    })}
                    
                </p>
            </Center>


            <Grid>
                <div className='col-1'data-sal='slide-right' data-sal-easing="ease-in-circ">
                    <h3>
                        {translateObject({
                            en: `Follow up your customers`,
                            es: `Seguimiento a tus clientes`
                        })}
                        </h3>
                    <p>
                        {translateObject({
                            en: `Give personalized follow-up to customers who have wanted to be
                            contacted through Dotell (the customer survey tool), with the data they provided, 
                            get in touch and continue creating a unique experience for your customers.`,
                            es: `Da seguimiento personalizado a los clientes que han querido ser contactados 
                            a través de Dotell (la herramienta de encuestas a clientes), con los datos que te han facilitado, 
                            ponte en contacto y sigue creando una experiencia única para tus clientes.`
                        })}
                    </p>
                </div>

                <div className='col-2'data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-duration='300'>
                    <StaticImage
                        src="../../../images/appsinti-1.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="appsinti-1"
                        style={{ width: '100%', height: 'fit-content' }}
                    />
                </div>

                <div className='col-3'data-sal='slide-left' data-sal-easing="ease-in-circ">
                    <h3>
                        {translateObject({
                            en: `As if you were there in person`,
                            es: `Como si estuvieras en persona`
                        })}
                    </h3>
                    <p>
                        {translateObject({
                            en: `Have contact with your staff to solve problems with customers as if you were present. 
                            It also tracks in real time the sales that are made and later view them in graphs of different periods of time.`,
                            es: `Ten contacto con tu personal para solucionar problemas con los clientes como si estuvieras presente. 
                            También realiza un seguimiento en tiempo real de las ventas que se realizan para luego visualizarlas en gráficos de diferentes periodos.`
                        })}
                    </p>
                </div>
            </Grid>
        </Container>
    )
};

export default Description;